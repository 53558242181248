import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/app/appEventBannerActions';

interface AppEventBannerState {
  isLoading: boolean;
  error: string | null;
  types: {
    typeNo: string;
    typeEnglishName: string;
  }[];
  banners: {
    seq: number;
    typeEnglishName: string;
    imageUrl: string;
    disabledFlag: boolean;
    textContent: string;
    utcCdt: string;
    utcUdt: string;
  }[];
  selectBanner: {
    seq: number;
    typeNo: string;
    typeEnglishName: string;
    imageUrl: string;
    disabledFlag: boolean;
    textContent: string;
    utcCdt: string;
    utcUdt: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  types: [],
  banners: [],
  selectBanner: null,
};

const appEventBannerReducer = (
  state: AppEventBannerState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_BANNER_TYPES_LIST_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNER_TYPES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: action.payload,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNER_TYPES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNERS_LIST_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNERS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: state.types,
        banners: action.payload,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNERS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNER_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.FETCH_EVENT_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: action.payload,
      };
    case ActionType.FETCH_EVENT_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.UPDATE_EVENT_BANNER_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: state.selectBanner,
      };
    case ActionType.UPDATE_EVENT_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: state.types,
        banners: state.banners.map((banner) => {
          if (banner.seq === action.payload.seq) {
            return action.payload;
          }
          return banner;
        }),
        selectBanner: state.selectBanner,
      };
    case ActionType.UPDATE_EVENT_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: state.selectBanner,
      };
    case ActionType.CREATE_EVENT_BANNER_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.CREATE_EVENT_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: state.types,
        banners: [...state.banners, action.payload],
        selectBanner: null,
      };
    case ActionType.CREATE_EVENT_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.DELETE_EVENT_BANNER_START:
      return {
        isLoading: true,
        error: null,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    case ActionType.DELETE_EVENT_BANNER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        types: state.types,
        banners: state.banners.filter(
          (banner) => banner.seq !== action.payload
        ),
        selectBanner: null,
      };
    case ActionType.DELETE_EVENT_BANNER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        types: state.types,
        banners: state.banners,
        selectBanner: null,
      };
    default:
      return state;
  }
};

export default appEventBannerReducer;
