import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/app/appImportantNoticeActions';

interface AppImportantNoticeState {
  isLoading: boolean;
  error: string | null;
  noticeList: {
    region: String;
    title: String;
    description: String;
    startTime: String;
    endTime: String;
    cdt: String;
    udt: String;
    creator: String;
    reviser: String;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  noticeList: [],
};

const appImportantNoticeReducer = (
  state: AppImportantNoticeState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_IMPORTANT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        noticeList: state.noticeList,
      };
    case ActionType.FETCH_IMPORTANT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        noticeList: action.payload,
      };
    case ActionType.FETCH_IMPORTANT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        noticeList: state.noticeList,
      };
    case ActionType.UPDATE_IMPORTANT_NOTICE_START:
      return {
        isLoading: true,
        error: null,
        noticeList: state.noticeList,
      };
    case ActionType.UPDATE_IMPORTANT_NOTICE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        noticeList: state.noticeList.map((region) => {
          if (region.region === action.payload.region) {
            return action.payload;
          }
          return region;
        }),
      };
    case ActionType.UPDATE_IMPORTANT_NOTICE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        noticeList: state.noticeList,
      };
    default:
      return state;
  }
};

export default appImportantNoticeReducer;
