import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/programTypeActions';

export interface ProgramTypesState {
  isLoading: boolean;
  error: string | null;
  programTypes: {
    typeSeq: number;
    typeEnglishName: string;
    typeChineseName: null;
    sort: number;
  }[];
  selectProgramType: {
    typeSeq: number;
    typeEnglishName: string;
    typeChineseName: null;
    sort: number;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  programTypes: [],
  selectProgramType: null,
};

const programTypesReducer = (
  state: ProgramTypesState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PROGRAM_TYPES_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.FETCH_PROGRAM_TYPES_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: action.payload,
        selectProgramType: null,
      };
    case ActionType.FETCH_PROGRAM_TYPES_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.FETCH_PROGRAM_TYPE_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.FETCH_PROGRAM_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: action.payload,
      };
    case ActionType.FETCH_PROGRAM_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_SORT_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_SORT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_SORT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.CREATE_PROGRAM_TYPE_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.CREATE_PROGRAM_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: [...state.programTypes, action.payload],
        selectProgramType: null,
      };
    case ActionType.CREATE_PROGRAM_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: state.programTypes.map((type) => {
          if (type.typeSeq === action.payload.typeSeq) {
            return action.payload;
          }
          return type;
        }),
        selectProgramType: null,
      };
    case ActionType.UPDATE_PROGRAM_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.DELETE_PROGRAM_TYPE_START:
      return {
        isLoading: true,
        error: null,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    case ActionType.DELETE_PROGRAM_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programTypes: state.programTypes.filter(
          (type) => type.typeSeq !== action.payload
        ),
        selectProgramType: null,
      };
    case ActionType.DELETE_PROGRAM_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programTypes: state.programTypes,
        selectProgramType: null,
      };
    default:
      return state;
  }
};

export default programTypesReducer;
