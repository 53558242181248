// @ts-nocheck
import './ErrorFallback.css';

const ErrorFallback = () => {
  return (
    <div className="error-container">
      <div className="error-502__container">
        <div className="error-502">
          <div className="error-502__text">
            <h1>
              Unexpected Error <b>:(</b>
            </h1>
            <h2>
              <p>You can refresh this page and try again.</p>
            </h2>
          </div>
        </div>
      </div>
      <div className="gears">
        <div className="gear one">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="gear two">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="gear three">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
