import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/crew/crewPointsValueActions';

export interface CrewPointsValueState {
  isLoading: boolean;
  error: string | null;
  selectCrewList: {
    empNo: string;
    firstName: string;
    lastName: string;
    chineseName: string;
    isLeaved: boolean;
  }[];
  actPointsValue: {
    seq: number;
    crewNo: string;
    pointsType: string;
    renewDt: string;
    pointValue: number;
    taxRate: number;
    remark: string;
  }[];
  tePointsValue: {
    seq: number;
    crewNo: string;
    pointsType: string;
    renewDt: string;
    pointValue: number;
    taxRate: number;
    remark: string;
  }[];
  otherPointsValue: {
    seq: number;
    crewNo: string;
    pointsType: string;
    renewDt: string;
    pointValue: number;
    taxRate: number;
    remark: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  selectCrewList: [],
  actPointsValue: [],
  tePointsValue: [],
  otherPointsValue: [],
};

const crewPointsValueReducer = (
  state: CrewPointsValueState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SELECT_CREW_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.FETCH_SELECT_CREW_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: action.payload,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.FETCH_SELECT_CREW_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.FETCH_POINTS_VALUE_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.FETCH_POINTS_VALUE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: action.payload.activity,
        tePointsValue: action.payload.te,
        otherPointsValue: action.payload.other,
      };
    case ActionType.FETCH_POINTS_VALUE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.CREATE_POINTS_VALUE_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.CREATE_POINTS_VALUE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: action.payload.activity,
        tePointsValue: action.payload.te,
        otherPointsValue: action.payload.other,
      };
    case ActionType.CREATE_POINTS_VALUE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.UPDATE_POINTS_VALUE_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.UPDATE_POINTS_VALUE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: action.payload.activity,
        tePointsValue: action.payload.te,
        otherPointsValue: action.payload.other,
      };
    case ActionType.UPDATE_POINTS_VALUE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.DELETE_POINTS_VALUE_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    case ActionType.DELETE_POINTS_VALUE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue.filter(
          (value) => value.seq !== action.payload
        ),
        tePointsValue: state.tePointsValue.filter(
          (value) => value.seq !== action.payload
        ),
        otherPointsValue: state.otherPointsValue.filter(
          (value) => value.seq !== action.payload
        ),
      };
    case ActionType.DELETE_POINTS_VALUE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        actPointsValue: state.actPointsValue,
        tePointsValue: state.tePointsValue,
        otherPointsValue: state.otherPointsValue,
      };
    default:
      return state;
  }
};

export default crewPointsValueReducer;
