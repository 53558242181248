import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/crew/crewPointsActions';

export interface CrewPointsState {
  isLoading: boolean;
  error: string | null;
  selectCrewList: {
    empNo: string;
    firstName: string;
    lastName: string;
    chineseName: string;
    isLeaved: boolean;
  }[];
  pointsValueSummary: {
    activity: {
      seq: number;
      crewNo: string;
      pointsType: string;
      renewDt: string;
      pointValue: number;
      taxRate: number;
      remark: string;
    };
    te: {
      seq: number;
      crewNo: string;
      pointsType: string;
      renewDt: string;
      pointValue: number;
      taxRate: number;
      remark: string;
    };
    other: {
      seq: number;
      crewNo: string;
      pointsType: string;
      renewDt: string;
      pointValue: number;
      taxRate: number;
      remark: string;
    } | null;
  } | null;
  pointsList: {
    seq: number;
    crewNo: null;
    firstName: null;
    lastName: null;
    startDt: string;
    region: string;
    content: string;
    actType: string;
    actData: null;
    activityType: string;
    activityData: string;
    points: number;
    pointValue: number;
    total: number;
    paidDt: string;
    remark: string;
    organizer: string;
    reviser: string;
    cdt: string;
    udt: string;
  }[];
  areaList: {
    typeNo: string;
    typeDesc: string;
  }[];
  contentList: {
    typeNo: string;
    typeDesc: string;
  }[];
  typeList: {
    typeNo: string;
    typeDesc: string;
  }[];
  dataList: {
    typeNo: string;
    typeDesc: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  selectCrewList: [],
  pointsValueSummary: null,
  pointsList: [],
  areaList: [],
  contentList: [],
  typeList: [],
  dataList: [],
};

const crewPointsReducer = (
  state: CrewPointsState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SELECT_CREW_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_SELECT_CREW_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: action.payload,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_SELECT_CREW_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_VALUE_SUMMARY_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_VALUE_SUMMARY_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: action.payload,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_VALUE_SUMMARY_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: action.payload,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_POINTS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_AREA_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_AREA_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: action.payload,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_AREA_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_CONTENT_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_CONTENT_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: action.payload,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_CONTENT_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_TYPE_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_TYPE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: action.payload,
        dataList: state.dataList,
      };
    case ActionType.FETCH_TYPE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_DATA_LIST_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.FETCH_DATA_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: action.payload,
      };
    case ActionType.FETCH_DATA_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.CREATE_POINT_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.CREATE_POINT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.CREATE_POINT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.DELETE_POINT_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.DELETE_POINT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList.filter(
          (record) => record.seq !== action.payload
        ),
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.DELETE_POINT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_POINT_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_POINT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_POINT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_PAY_DATE_START:
      return {
        isLoading: true,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_PAY_DATE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    case ActionType.UPDATE_PAY_DATE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        selectCrewList: state.selectCrewList,
        pointsValueSummary: state.pointsValueSummary,
        pointsList: state.pointsList,
        areaList: state.areaList,
        contentList: state.contentList,
        typeList: state.typeList,
        dataList: state.dataList,
      };
    default:
      return state;
  }
};

export default crewPointsReducer;
