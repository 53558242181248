import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/evaluationActions';

interface EvaluationState {
  isLoading: boolean;
  error: string | null;
  evaluations: {
    typeOfAge: string;
    teAbilityDetailMessageGroups: {
      typeOfAbility: string;
      teAbilityDetailRatings: {
        numberOfStars: number;
        teAbilityMessages: {
          evaluationId: number;
          evaluation: string;
        }[];
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  evaluations: [],
};

const evaluationReducer = (
  state: EvaluationState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVALUATION_START:
      return {
        isLoading: true,
        error: null,
        evaluations: state.evaluations,
      };
    case ActionType.FETCH_EVALUATION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        evaluations: action.payload,
      };
    case ActionType.FETCH_EVALUATION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        evaluations: state.evaluations,
      };
    case ActionType.UPDATE_EVALUATION_START:
      return {
        isLoading: true,
        error: null,
        evaluations: state.evaluations,
      };
    case ActionType.UPDATE_EVALUATION_SUCCESS:
      return {
        isLoading: false,
        error: null,
        evaluations: state.evaluations,
      };
    case ActionType.UPDATE_EVALUATION_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        evaluations: state.evaluations,
      };
    default:
      return state;
  }
};

export default evaluationReducer;
