import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/lessonActions';

interface LessonState {
  isLoading: boolean;
  error: string | null;
  lessons: {
    seq: number;
    lessonNumber: string;
    lessonName: string;
    typeValue: string;
    typeValue1: string;
    useFg: boolean;
    ctiFg: boolean;
    teCategory: string;
    color: string;
    mp3File: string;
    canEdit: boolean;
    lessonContent: null;
  }[];
  selectLesson: {
    seq: number;
    lessonNumber: string;
    lessonName: string;
    typeValue: string;
    typeValue1: string;
    useFg: boolean;
    ctiFg: boolean;
    teCategory: string;
    color: string;
    mp3File: string;
    canEdit: boolean;
    lessonContent: {
      mainSeq: number;
      versionNumber: string;
      coverImageUrl: string;
      lessonContentDetail: {
        detailSeq: number;
        subTopic: string;
        content: string;
        contentImageUrl: string;
      }[];
    };
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  lessons: [],
  selectLesson: null,
};

const lessonReducer = (state: LessonState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_LESSONS_LIST_START:
      return {
        isLoading: true,
        error: null,
        lessons: state.lessons,
        selectLesson: null,
      };
    case ActionType.FETCH_LESSONS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lessons: action.payload,
        selectLesson: null,
      };
    case ActionType.FETCH_LESSONS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lessons: state.lessons,
        selectLesson: null,
      };
    case ActionType.FETCH_LESSON_START:
      return {
        isLoading: true,
        error: null,
        lessons: state.lessons,
        selectLesson: null,
      };
    case ActionType.FETCH_LESSON_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lessons: state.lessons,
        selectLesson: action.payload,
      };
    case ActionType.FETCH_LESSON_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lessons: state.lessons,
        selectLesson: null,
      };
    case ActionType.UPDATE_LESSON_START:
      return {
        isLoading: true,
        error: null,
        lessons: state.lessons,
        selectLesson: null,
      };
    case ActionType.UPDATE_LESSON_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lessons: state.lessons.map((lesson) => {
          if (lesson.seq === action.payload.seq) {
            return action.payload;
          }
          return lesson;
        }),
        selectLesson: null,
      };
    case ActionType.UPDATE_LESSON_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lessons: state.lessons,
        selectLesson: null,
      };
    default:
      return state;
  }
};

export default lessonReducer;
