import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/taxInfoActions';

interface TaxInfoState {
  isLoading: boolean;
  error: string | null;
  taxInfo: {
    actArea: string;
    typeDesc: string;
    taxMemoContent: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  taxInfo: [],
};

const taxInfoReducer = (state: TaxInfoState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_TAX_INFO_LIST_START:
      return {
        isLoading: true,
        error: null,
        taxInfo: state.taxInfo,
      };
    case ActionType.FETCH_TAX_INFO_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        taxInfo: action.payload,
      };
    case ActionType.FETCH_TAX_INFO_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        taxInfo: state.taxInfo,
      };
    case ActionType.UPDATE_TAX_INFO_START:
      return {
        isLoading: true,
        error: null,
        taxInfo: state.taxInfo,
      };
    case ActionType.UPDATE_TAX_INFO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        taxInfo: state.taxInfo.map((item) => {
          if (item.actArea === action.payload.actArea) {
            return action.payload;
          }
          return item;
        }),
      };
    case ActionType.UPDATE_TAX_INFO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        taxInfo: state.taxInfo,
      };
    default:
      return state;
  }
};

export default taxInfoReducer;
