import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/groupPermissionActions';

export interface GroupPermissionState {
  isLoading: boolean;
  error: string | null;
  groupsName: {
    groupName: string;
  }[];
  selectGroup: {
    groupName: string;
    checkedProgramIds: string[];
  } | null;
  groups: {
    title: string;
    key: string;
    children: {
      title: string;
      key: string;
      children: {
        title: string;
        key: string;
        children: any;
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  groupsName: [],
  selectGroup: null,
  groups: [],
};

const groupPermissionReducer = (
  state: GroupPermissionState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_GROUPS_NAME_LIST_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUPS_NAME_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: action.payload,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUPS_NAME_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.DELETE_GROUP_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.DELETE_GROUP_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: state.groupsName.filter(
          (group) => group.groupName !== action.payload
        ),
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.DELETE_GROUP_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUPS_LIST_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUPS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: state.groupsName,
        selectGroup: state.selectGroup,
        groups: action.payload,
      };
    case ActionType.FETCH_GROUPS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.CREATE_GROUP_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.CREATE_GROUP_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: [...state.groupsName, action.payload],
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.CREATE_GROUP_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUP_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUP_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: state.groupsName,
        selectGroup: action.payload,
        groups: state.groups,
      };
    case ActionType.FETCH_GROUP_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.UPDATE_GROUP_START:
      return {
        isLoading: true,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.UPDATE_GROUP_SUCCESS:
      return {
        isLoading: false,
        error: null,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    case ActionType.UPDATE_GROUP_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        groupsName: state.groupsName,
        selectGroup: null,
        groups: state.groups,
      };
    default:
      return state;
  }
};

export default groupPermissionReducer;
