import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/gift/giftActions';

export interface GiftsState {
  isLoading: boolean;
  error: string | null;
  inStockGifts: {
    giftNo: string;
    giftName: string;
    image: string;
    description: string;
    spec: string;
    importPrice: number;
    points: number;
    totalQty: number;
    useQty: number;
    inventoryQty: number;
    adjustQty: number;
  }[];
  outOfStockGifts: {
    giftNo: string;
    giftName: string;
    image: string;
    description: string;
    spec: string;
    importPrice: number;
    points: number;
    totalQty: number;
    useQty: number;
    inventoryQty: number;
    adjustQty: number;
  }[];
  selectGift: {
    giftNo: string;
    giftName: string;
    image: string;
    description: string;
    spec: string;
    importPrice: number;
    points: number;
    totalQty: number;
    useQty: number;
    inventoryQty: number;
    adjustQty: number;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  inStockGifts: [],
  outOfStockGifts: [],
  selectGift: null,
};

const giftsReducer = (state: GiftsState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_GIFTS_LIST_START:
      return {
        isLoading: true,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.FETCH_GIFTS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        inStockGifts: action.payload.inStockGifts,
        outOfStockGifts: action.payload.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.FETCH_GIFTS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.CREATE_GIFT_START:
      return {
        isLoading: true,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.CREATE_GIFT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        inStockGifts:
          action.payload.totalQty !== 0
            ? [...state.inStockGifts, action.payload]
            : state.inStockGifts,
        outOfStockGifts:
          action.payload.totalQty === 0
            ? [...state.outOfStockGifts, action.payload]
            : state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.CREATE_GIFT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.DELETE_GIFT_START:
      return {
        isLoading: true,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.DELETE_GIFT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        inStockGifts: state.inStockGifts.filter(
          (gift) => gift.giftNo !== action.payload
        ),
        outOfStockGifts: state.outOfStockGifts.filter(
          (gift) => gift.giftNo !== action.payload
        ),
        selectGift: null,
      };
    case ActionType.DELETE_GIFT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.FETCH_GIFT_START:
      return {
        isLoading: true,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.FETCH_GIFT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: action.payload,
      };
    case ActionType.FETCH_GIFT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.UPDATE_GIFT_START:
      return {
        isLoading: true,
        error: null,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.UPDATE_GIFT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        inStockGifts:
          action.payload.totalQty > 0
            ? state.inStockGifts.map((gift) => {
                if (gift.giftNo === action.payload.giftNo) {
                  return action.payload;
                }
                return gift;
              })
            : state.inStockGifts,
        outOfStockGifts:
          action.payload.totalQty <= 0
            ? state.outOfStockGifts.map((gift) => {
                if (gift.giftNo === action.payload.giftNo) {
                  return action.payload;
                }
                return gift;
              })
            : state.outOfStockGifts,
        selectGift: null,
      };
    case ActionType.UPDATE_GIFT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        inStockGifts: state.inStockGifts,
        outOfStockGifts: state.outOfStockGifts,
        selectGift: null,
      };
    default:
      return state;
  }
};

export default giftsReducer;
