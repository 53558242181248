import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/freeTalkTopicActions';

interface FreeTalkTopicState {
  isLoading: boolean;
  error: string | null;
  topics: {
    seq: number;
    topic: string;
    questions: string;
    isUsed: boolean;
  }[];
  selectTopic: {
    seq: number;
    topic: string;
    questions: string;
    isUsed: boolean;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  topics: [],
  selectTopic: null,
};

const freeTalkTopicReducer = (
  state: FreeTalkTopicState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_FREE_TALK_TOPICS_LIST_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.FETCH_FREE_TALK_TOPICS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: action.payload,
        selectTopic: null,
      };
    case ActionType.FETCH_FREE_TALK_TOPICS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.CREATE_FREE_TALK_TOPIC_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.CREATE_FREE_TALK_TOPIC_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: [...state.topics, action.payload],
        selectTopic: null,
      };
    case ActionType.CREATE_FREE_TALK_TOPIC_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.FETCH_FREE_TALK_TOPIC_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.FETCH_FREE_TALK_TOPIC_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: state.topics,
        selectTopic: action.payload,
      };
    case ActionType.FETCH_FREE_TALK_TOPIC_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.UPDATE_FREE_TALK_TOPIC_START:
      return {
        isLoading: true,
        error: null,
        topics: state.topics,
        selectTopic: null,
      };
    case ActionType.UPDATE_FREE_TALK_TOPIC_SUCCESS:
      return {
        isLoading: false,
        error: null,
        topics: state.topics.map((topic) => {
          if (topic.seq === action.payload.seq) {
            return action.payload;
          }
          return topic;
        }),
        selectTopic: null,
      };
    case ActionType.UPDATE_FREE_TALK_TOPIC_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        topics: state.topics,
        selectTopic: null,
      };
    default:
      return state;
  }
};

export default freeTalkTopicReducer;
