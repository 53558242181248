import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/sys/programActions';

export interface ProgramsState {
  isLoading: boolean;
  error: string | null;
  programs: {
    programId: number;
    programChineseName: string;
    programEnglishName: string;
    menuSeq: number;
    menuEnglishName: string;
    menuChineseName: string;
    typeSeq: number;
    typeEnglishName: string;
    typeChineseName: string;
    sort: number;
    path: string;
    oldPath?: string;
  }[];
  selectProgram: {
    programId: number;
    programChineseName: string;
    programEnglishName: string;
    menuSeq: number;
    menuEnglishName: string;
    menuChineseName: string;
    typeSeq: number;
    typeEnglishName: string;
    typeChineseName: string;
    sort: number;
    path: string;
    oldPath?: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  programs: [],
  selectProgram: null,
};

const programsReducer = (
  state: ProgramsState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PROGRAMS_LIST_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.FETCH_PROGRAMS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: action.payload,
        selectProgram: null,
      };
    case ActionType.FETCH_PROGRAMS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_SORT_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_SORT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_SORT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.DELETE_PROGRAM_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.DELETE_PROGRAM_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: state.programs.filter(
          (program) => program.programId !== action.payload
        ),
        selectProgram: null,
      };
    case ActionType.DELETE_PROGRAM_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.CREATE_PROGRAM_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.CREATE_PROGRAM_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: [...state.programs, action.payload],
        selectProgram: null,
      };
    case ActionType.CREATE_PROGRAM_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.FETCH_PROGRAM_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.FETCH_PROGRAM_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: state.programs,
        selectProgram: action.payload,
      };
    case ActionType.FETCH_PROGRAM_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_START:
      return {
        isLoading: true,
        error: null,
        programs: state.programs,
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_SUCCESS:
      return {
        isLoading: false,
        error: null,
        programs: state.programs.map((program) => {
          if (program.programId === action.payload.programId) {
            return action.payload;
          }
          return program;
        }),
        selectProgram: null,
      };
    case ActionType.UPDATE_PROGRAM_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        programs: state.programs,
        selectProgram: null,
      };
    default:
      return state;
  }
};

export default programsReducer;
