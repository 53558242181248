import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/performerActions';

interface PerformersState {
  isLoading: boolean;
  error: string | null;
  performers: {
    performerId: number;
    performerName: string;
    countryName: string;
    photoUrl: string;
    topDate: string;
    startDate: string;
    endDate: string;
    isUsed: string;
    roleId: string;
    roleName: string;
  }[];
  selectPerformer: {
    performerId: number;
    performerName: string;
    countryName: string;
    photoUrl: string;
    topDate: string;
    startDate: string;
    endDate: string;
    isUsed: string;
    roleId: string;
    roleName: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  performers: [],
  selectPerformer: null,
};

const performersReducer = (
  state: PerformersState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PERFORMERS_LIST_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.FETCH_PERFORMERS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: action.payload,
        selectPerformer: null,
      };
    case ActionType.FETCH_PERFORMERS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.CREATE_PERFORMER_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.CREATE_PERFORMER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: [...state.performers, action.payload],
        selectPerformer: null,
      };
    case ActionType.CREATE_PERFORMER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.FETCH_PERFORMER_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.FETCH_PERFORMER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers,
        selectPerformer: action.payload,
      };
    case ActionType.FETCH_PERFORMER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.UPDATE_PERFORMER_START:
      return {
        isLoading: true,
        error: null,
        performers: state.performers,
        selectPerformer: null,
      };
    case ActionType.UPDATE_PERFORMER_SUCCESS:
      return {
        isLoading: false,
        error: null,
        performers: state.performers.map((performer) => {
          if (performer.performerId === action.payload.performerId) {
            return action.payload;
          }
          return performer;
        }),
        selectPerformer: null,
      };
    case ActionType.UPDATE_PERFORMER_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        performers: state.performers,
        selectPerformer: null,
      };
    default:
      return state;
  }
};

export default performersReducer;
