import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/app/appHomeImageActions';

interface AppHomeImageState {
  isLoading: boolean;
  error: string | null;
  regions: string[];
  hkImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: null;
    endDate: null;
  }[];
  hkFestivalImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: string;
    endDate: string;
  }[];
  krImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: null;
    endDate: null;
  }[];
  krFestivalImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: string;
    endDate: string;
  }[];
  newMarketImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: null;
    endDate: null;
  }[];
  newMarketFestivalImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: string;
    endDate: string;
  }[];
  twImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: null;
    endDate: null;
  }[];
  twFestivalImages: {
    regionId: string;
    serviceId: string;
    festivalId: string;
    imageUrl: string;
    startDate: string;
    endDate: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  regions: [],
  hkImages: [],
  hkFestivalImages: [],
  krImages: [],
  krFestivalImages: [],
  newMarketImages: [],
  newMarketFestivalImages: [],
  twImages: [],
  twFestivalImages: [],
};

const appHomeImageReducer = (
  state: AppHomeImageState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_APP_HOME_IMAGE_LIST_START:
      return {
        isLoading: true,
        error: null,
        regions: state.regions,
        hkImages: state.hkImages,
        hkFestivalImages: state.hkFestivalImages,
        krImages: state.krImages,
        krFestivalImages: state.krFestivalImages,
        newMarketImages: state.newMarketImages,
        newMarketFestivalImages: state.newMarketFestivalImages,
        twImages: state.twImages,
        twFestivalImages: state.twFestivalImages,
      };
    case ActionType.FETCH_APP_HOME_IMAGE_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        regions: action.payload.regionIds,
        hkImages: action.payload.regionImages.filter(
          (image) => image.regionId === 'hk'
        ),
        hkFestivalImages: action.payload.regionFestivalImages.filter(
          (image) => image.regionId === 'hk'
        ),
        krImages: action.payload.regionImages.filter(
          (image) => image.regionId === 'kr'
        ),
        krFestivalImages: action.payload.regionFestivalImages.filter(
          (image) => image.regionId === 'kr'
        ),
        newMarketImages: action.payload.regionImages.filter(
          (image) => image.regionId === 'new_market'
        ),
        newMarketFestivalImages: action.payload.regionFestivalImages.filter(
          (image) => image.regionId === 'new_market'
        ),
        twImages: action.payload.regionImages.filter(
          (image) => image.regionId === 'tw'
        ),
        twFestivalImages: action.payload.regionFestivalImages.filter(
          (image) => image.regionId === 'tw'
        ),
      };
    case ActionType.FETCH_APP_HOME_IMAGE_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        regions: state.regions,
        hkImages: state.hkImages,
        hkFestivalImages: state.hkFestivalImages,
        krImages: state.krImages,
        krFestivalImages: state.krFestivalImages,
        newMarketImages: state.newMarketImages,
        newMarketFestivalImages: state.newMarketFestivalImages,
        twImages: state.twImages,
        twFestivalImages: state.twFestivalImages,
      };
    case ActionType.UPDATE_APP_HOME_IMAGE_START:
      return {
        isLoading: true,
        error: null,
        regions: state.regions,
        hkImages: state.hkImages,
        hkFestivalImages: state.hkFestivalImages,
        krImages: state.krImages,
        krFestivalImages: state.krFestivalImages,
        newMarketImages: state.newMarketImages,
        newMarketFestivalImages: state.newMarketFestivalImages,
        twImages: state.twImages,
        twFestivalImages: state.twFestivalImages,
      };
    case ActionType.UPDATE_APP_HOME_IMAGE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        regions: state.regions,
        hkImages: state.hkImages,
        hkFestivalImages: state.hkFestivalImages,
        krImages: state.krImages,
        krFestivalImages: state.krFestivalImages,
        newMarketImages: state.newMarketImages,
        newMarketFestivalImages: state.newMarketFestivalImages,
        twImages: state.twImages,
        twFestivalImages: state.twFestivalImages,
      };
    case ActionType.UPDATE_APP_HOME_IMAGE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        regions: state.regions,
        hkImages: state.hkImages,
        hkFestivalImages: state.hkFestivalImages,
        krImages: state.krImages,
        krFestivalImages: state.krFestivalImages,
        newMarketImages: state.newMarketImages,
        newMarketFestivalImages: state.newMarketFestivalImages,
        twImages: state.twImages,
        twFestivalImages: state.twFestivalImages,
      };
    default:
      return state;
  }
};

export default appHomeImageReducer;
