import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/serviceUsage/serviceUsageActions';

interface ServiceUsageState {
  isLoading: boolean;
  error: string | null;
  serviceUsageList: {
    yearMonth: string;
    detail: {
      type: string;
      stats: {
        title: string;
        qty: string;
      }[];
    }[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  serviceUsageList: [],
};

const serviceUsageReducer = (
  state: ServiceUsageState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SERVICE_USAGE_START:
      return {
        isLoading: true,
        error: null,
        serviceUsageList: state.serviceUsageList,
      };
    case ActionType.FETCH_SERVICE_USAGE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        serviceUsageList: action.payload,
      };
    case ActionType.FETCH_SERVICE_USAGE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        serviceUsageList: state.serviceUsageList,
      };
    default:
      return state;
  }
};

export default serviceUsageReducer;
