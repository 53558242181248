const zh_TW = {
  "app.signIn": "使用您的寰宇家庭微軟帳號登入",
  "app.signOut": "登出",
  "app.giftPoints": "學習點數",
  "app.video": "影片管理",
  "app.sms": "簡訊及推播管理",
  "app.TE": "電話美語",
  "app.cap": "CAP 管理",
  "app.gpMaintain": "禮物點數維護",
  "app.mainVideoType": "影片主類別管理",
  "app.subVideoType": "影片次類別管理",
  "app.videoInfo": "影片管理",
  "app.sendSms": "俱樂部快訊",
  "app.evaluation": "評量訊息管理",
  "app.faceCallPerformer": "Face Call 表演者管理",
  "app.byPerformer": "Face Call by Performer",
  "app.bySong": "Face Call by Song",
  "app.capItemRelationship": "CAP Item 清單",
  "app.capChecking": "CAP 作業審核",
  "app.mainMenu": "主選單",
  "app.inStocks": "有庫存",
  "app.outOfStocks": "無庫存",
  "app.createNew": "新增",
  "app.giftNo": "禮物編號",
  "app.image": "圖片",
  "app.giftName": "禮物名稱",
  "app.price": "進價",
  "app.qty": "數量",
  "app.points": "兌換點數",
  "app.createDate": "建立日期",
  "app.action": "編輯",
  "app.createNewGift": "新增禮物",
  "app.upload": "選擇圖檔",
  "app.uploadImage": "上傳圖檔",
  "app.giftDescription": "禮物介紹",
  "app.giftSpec": "規格",
  "app.updateGift": "更新禮物",
  "app.submit": "送出",
  "app.cancel": "取消",
  "app.videoTypeName": "類別名稱",
  "app.createNewMainVideoType": "新增影片主類別",
  "app.updateMainVideoType": "更新影片主類別",
  "app.selectMainVideoType": "選擇主類別",
  "app.selectSubVideoType": "選擇次類別",
  "app.mainVideoTypeName": "主類別名稱",
  "app.subVideoTypeName": "次類別名稱",
  "app.createNewSubVideoType": "新增影片次類別",
  "app.currentSubTypes": "目前次類別",
  "app.addInputField": "新增欄位",
  "app.updateSubVideoType": "更新影片次類別",
  "app.seq": "編號",
  "app.title": "標題",
  "app.specialView": "特殊觀看",
  "app.openDate": "開播時間",
  "app.disabled": "停用",
  "app.createNewVideo": "新增影片",
  "app.videoUrl": "影片連結{type}",
  "app.videoDescription": "影片說明",
  "app.updateVideo": "更新影片",
  "app.targets": "發送對象",
  "app.sendSingle": "單一發送",
  "app.sendAll": "全部發送",
  "app.sendSome": "特定發送",
  "app.phoneNo": "會員編號",
  "app.smsMsg": "訊息內容",
  "app.performerName": "Performer 姓名",
  "app.country": "國家",
  "app.pStartDate": "加入日期",
  "app.pResignDate": "離職日期",
  "app.createNewPerformer": "新增 Performer",
  "app.updatePerformer": "更新 Performer",
  "app.selectPerformer": "選擇 Performer",
  "app.videoList": "影片清單",
  "app.addNewVideo": "新增影片",
  "app.songName": "歌曲名稱",
  "app.onlineDate": "上線日期",
  "app.offlineDate": "下線日期",
  "app.videoLink": "影片 ID",
  "app.preview": "預覽",
  "app.selectSong": "選擇歌曲",
  "app.performerList": "Performer 清單",
  "app.videoPreview": "影片預覽",
  "app.subItemNo": "次項目編號",
  "app.subItemName": "次項目名稱",
  "app.newSubItemName": "次項目新名稱",
  "app.verifyMsgTw": "驗證訊息(繁中)",
  "app.verifyMsgEn": "驗證訊息(英)",
  "app.applyDate": "申請日",
  "app.memberNo": "會員編號",
  "app.childNameTw": "小孩姓名(中)",
  "app.childNameEn": "小孩姓名(英)",
  "app.childBDay": "小孩生日",
  "app.task": "作業名稱",
  "app.audio": "音檔",
  "app.approve": "審核",
  "app.playAudio": "播放音檔",
  "app.isApprove": "審核結果",
  "app.approveStatus": "審核狀態",
  "app.reason": "未通過原因",
  "app.remarks": "備註",
  "app.approved": "通過",
  "app.notApproved": "未通過",
  "app.reason1": "音檔無法讀取",
  "app.reason2": "音檔與內容不符",
  "app.reason3": "其他",
  "app.giftLabel": "禮物標籤列印",
  "app.printDate": "列印日期",
  "app.exchangeDate": "兌換日期",
  "app.memberName": "會員姓名",
  "app.exchangeDetail": "兌換內容",
  "app.dateFilter": "列印日期區間篩選",
  "app.filter": "篩選",
  "app.reset": "重置",
  "app.printPreview": "更新狀態並列印",
  "app.prepare": "請準備",
  "app.printUnit": "張",
  "app.printPaper": "6 格 A4 Label 紙",
  "app.print": "列印",
  "app.singlePrintResult": "列印結果(單一)",
  "app.C01": "藍色",
  "app.C02": "深藍",
  "app.C03": "綠色",
  "app.C04": "淺綠",
  "app.C05": "黃色",
  "app.role": "角色",
  "app.age1": "1 歲以下",
  "app.age2": "1 ~ 2 歲",
  "app.age3": "2 ~ 3 歲",
  "app.age4": "3 ~ 5 歲",
  "app.age5": "5 ~ 8 歲",
  "app.age6": "8 歲以上",
  "app.ability1": "歌唱力",
  "app.ability2": "發音",
  "app.ability3": "理解能力",
  "app.ability4": "說話能力",
  "app.ability5": "互動能力",
  "app.updateMsg": "更新訊息內容",
  "app.seasonOfPromo": "節日推廣",
  "app.halloween": "萬聖節",
  "app.xmas": "聖誕節",
  "app.cNewYear": "農曆新年",
  "app.seasonNone": "不指定",
  "app.monthStatistics": "月統計",
  "app.imageUrl": "圖片 URL",
  "app.sysPermission": "系統及權限管理",
  "app.groupPermission": "群組權限設定",
  "app.selectGroup": "選擇群組",
  "app.settingPermission": "權限設定",
  "app.groupName": "群組名稱",
  "app.createGroup": "新增群組及權限",
  "app.updateGroup": "更新群組及權限",
  "app.userPermission": "使用者群組設定",
  "app.userName": "使用者名稱",
  "app.userGroup": "所屬群組",
  "app.createUserGroup": "新增使用者所屬群組",
  "app.updateUserGroup": "更新使用者所屬群組",
  "app.memberStats": "會員統計資料",
  "app.serviceStats": "服務統計資料",
  "app.programTypes": "程式類別設定",
  "app.programTypeName": "程式類別名稱",
  "app.sort": "排序",
  "app.createProgramType": "新增程式類別",
  "app.programTypeEnName": "類別名稱(英)",
  "app.programTypeTwName": "類別名稱(繁中)",
  "app.updateProgramType": "更新程式類別",
  "app.programMenu": "程式選單名稱設定",
  "app.programMenuEnName": "程式選單名稱(英)",
  "app.programMenuTwName": "程式選單名稱(繁中)",
  "app.createModule": "新增程式選單名稱",
  "app.updateModule": "更新程式選單名稱",
  "app.iconName": "程式選單 Icon 名稱",
  "app.program": "程式名稱設定",
  "app.programEnName": "程式名稱(英)",
  "app.programTwName": "程式名稱(繁中)",
  "app.createProgram": "新增程式名稱",
  "app.updateProgram": "更新程式名稱",
  "app.programPath": "程式所在路徑",
  "app.serviceUsage": "Service Usage PBI",
  "app.lesson": "課程管理",
  "app.lessonName": "課程名稱",
  "app.lessonCategory": "課程類別",
  "app.usedInWebApp": "用於 Web/App",
  "app.usedInCTI": "用於 CTI",
  "app.updateLesson": "更新課程",
  "app.lessonNo": "課程編號",
  "app.remove": "移除音檔",
  "app.uploadAudio": "上傳音檔",
  "app.lessonCoverImg": "封面圖檔",
  "app.removeCoverImg": "移除封面",
  "app.uploadCover": "上傳封面",
  "app.lessonDetail": "課程明細",
  "app.lessonSectionNo": "課程段落編號",
  "app.createLessonSection": "新增課程段落",
  "app.lessonSectionImg": "段落圖片",
  "app.sectionContent": "段落內容",
  "app.updateLessonSection": "更新課程段落",
  "app.removeSectionImg": "移除段落圖片",
  "app.createLesson": "新增課程",
  "app.next": "下一步",
  "app.prev": "上一步",
  "app.lessonBasic": "基本資訊",
  "app.lessonSection": "段落資訊",
  "app.create": "新增",
  "app.update": "更新",
  "app.capApply": "CAP 作業繳交",
  "app.childName": "小孩姓名",
  "app.userCode": "員工編號",
  "app.selectProgramMenu": "選擇程式選單: ",
  "app.selectProgramType": "選擇程式類別: ",
  "app.goGoLive": "Go Go Live",
  "app.goToOld": "前往舊系統",
  "app.ie": "請複製以下連結前往 IE 開啟",
  "app.programOldPath": "舊系統程式所在路徑",
  "app.useQty": "已兌換數量",
  "app.adjustQty": "調整數量",
  "app.EVENT": "活動管理",
  "app.eventInformation": "活動資料維護",
  "app.eventAdditional": "活動批次加場",
  "app.eventVenue": "活動場地維護",
  "app.eventCheckIn": "活動批次報到",
  "app.eventNewMember": "迎新報名",
  "app.summerCamp": "夏令營報名",
  "app.eventSpecial": "特殊報名",
  "app.ecStaffImport": "員工報名美語嘉年華匯入",
  "app.eventTicket": "活動票列印",
  "app.eventPreview": "活動預習單報表",
  "app.ecSeatList": "活動座位報表",
  "app.eventPreviewVerify": "活動預習單檢核報表",
  "app.eventCredit": "活動保留款報表",
  "app.eventAssurance": "活動保險報表",
  "app.freelancerScheduling": "非正職人員排班報表",
  "app.activityCrewArrangement": "活動 Crew 排班報表",
  "app.activityCrewAdjustment": "活動 Crew 調度報表",
  "app.activityCrewPlanning": "活動 Crew 計畫表",
  "app.activityPayment": "活動特殊費用報表",
  "app.eventRegistered": "活動報名表",
  "app.eventList": "活動資料名冊查詢 - batch",
  "app.healthQuery": "健康聲明書查詢",
  "app.capProcess": "CAP 申請審核及維護",
  "app.holiday": "假日設定",
  "app.crewCountCreating": "電話美語開放預約數設定",
  "app.crewLevel": "電話美語老師等級設定",
  "app.supervisor": "Supervisor 名單維護",
  "app.crewSchedule": "電話美語時段設定",
  "app.teModify": "電話美語資訊修正",
  "app.dailyReport": "每日報表",
  "app.monthlyReport": "每月報表",
  "app.serviceReport": "服務明細表",
  "app.topTe": "熱門點播排行榜",
  "app.superKid": "Super Kid 名單",
  "app.teSchedule": "排班報表",
  "app.teTracking": "追蹤報表",
  "app.ecSeatSetting": "美語嘉年華座位管理",
  "app.ecSeat": "美語嘉年華座位圖設定",
  "app.ecSeatStatus": "美語嘉年華保留位設定",
  "app.Crew": "Crew 管理",
  "app.crewInformation": "Crew 資料管理",
  "app.dateControl": "付款前日期鎖定",
  "app.crewActivity": "Crew 活動維護",
  "app.crewActivityReport": "Crew 活動報表",
  "app.crewRequestForm": "Crew 付款報表",
  "app.crewPointsReport": "Crew 付款明細表",
  "app.checkIn": "報到管理",
  "app.checkInQuery": "報到查詢",
  "app.survey": "問卷管理",
  "app.surveyItem": "問卷題目設定",
  "app.surveyModel": "問卷回答選項設定",
  "app.surveyActivity": "問卷列印",
  "app.surveyScan": "問卷掃描",
  "app.surveyReport": "問卷報表",
  "app.surveyCommand": "問卷建議",
  "app.smsWaiting": "活動訊息寄送",
  "app.mailOrder": "會員郵購",
  "app.mailOrderOld": "郵購出貨明細表",
  "app.membershipReport": "會員分析報表",
  "app.agingReport": "會員期報表",
  "app.activeMemberReport": "有效會員報表",
  "app.eventNumber": "活動代號",
  "app.search": "查詢",
  "app.dlExcel": "下載 Excel 檔",
  "app.phone": "電話",
  "app.participants": "參加人數",
  "app.adult": "大人",
  "app.child": "小孩",
  "app.smsType": "訊息類別",
  "app.playVideo": "播放影片",
  "app.playMedia": "播放檔案",
  "app.capCheckingNotice": "自由審核頁籤僅顯示前 50 筆資料",
  "app.searchByMemNo": "輸入會員編號",
  "app.applyCount": "本月已繳交 {count} 次",
  "app.uploadMediaFile": "上傳媒體檔",
  "app.serviceAnalyzeReport": "Profile PBI",
  "app.childAge": "小孩年齡",
  "app.ulNotice":
    "上傳限制: 解析度( 長及寬小於等於300px ), 格式( jpeg ), 大小( 小於等於50KB )",
  "app.app": "APP 管理",
  "app.feedbackReport": "Club App Survey PBI",
  "app.monthlyRecommend": "每月精選設定",
  "app.month": "月份",
  "app.performer": "表演者",
  "app.updateMonthlyRecommend": "編輯每月精選",
  "app.year": "年份",
  "app.refFrom": "參照自",
  "app.freeChecking": "自由審核",
  "app.familyChecking": "家庭審核",
  "app.checkingStatus": "審核狀態",
  "app.checkingDate": "審核日期",
  "app.checkingRemark": "備註",
  "app.applyRequestDate": "繳交日期",
  "app.capQuery": "CAP query",
  "app.crewOnlineEvent": "Crew 線上活動維護",
  "app.crewName": "Crew 姓名",
  "app.freeTalkTopic": "Free Conversation 主題",
  "app.topic": "主題",
  "app.questions": "引導問題",
  "app.createFreeTalkTopic": "新增 Free Conversation 主題",
  "app.updateFreeTalkTopic": "更新 Free Conversation 主題",
  "app.monthlyFreeTalk": "每月 Free Conversation",
  "app.monthlyFreeTalkUpdate": "更新每月 Free Conversation",
  "app.appReleaseVersion": "版本設定",
  "app.version": "版本",
  "app.test": "測試版",
  "app.release": "正式版",
  "app.appBanner": "Home Message bar設定",
  "app.left": "左",
  "app.right": "右",
  "app.inUse": "使用中",
  "app.notUse": "停用中",
  "app.appBannerCreate": "新增 Home Message bar",
  "app.text": "文字",
  "app.textAlign": "文字對齊",
  "app.bannerLink": "連結至",
  "app.bannerNav": "導航至",
  "app.inUse?": "是否使用",
  "app.appBannerUpdate": "更新 Home Message bar",
  "app.appServiceUsage": "Service Summary",
  "app.region": "地區",
  "app.scheduleTime": "發送時間",
  "app.scheduleTimeRemark": "(未選時間則為立即傳送)",
  "app.capHatLabelPrint": "CAP 帽子寄送檔案匯出",
  "app.teCrewSeatComparison": "Crew 座位對照管理",
  "app.computerName": "電腦名稱",
  "app.extNumber": "分機號碼",
  "app.createCrewSeatComparison": "新增 Crew 座位對照",
  "app.updateCrewSeatComparison": "更新 Crew 座位對照",
  "app.teDailyLog": "Daily Log",
  "app.videoId": "影片 ID",
  "app.dashboard": "Dashboard",
  "app.membershipPBI": "Membership PBI",
  "app.teMonthlyReport": "Telephone English PBI",
  "app.newVideoSubType": "新次類別名稱",
  "app.capModify": "CAP 作業維護",
  "app.updateCapApplyRequestDate": "更新 CAP 作業繳交日期",
  "app.yesterday": "前一 TE 日",
  "app.oneDay": "特定日",
  "app.dateRange": "區間",
  "app.allNotSend": "所有未寄出標籤",
  "app.alreadySent": "已寄出過標籤",
  "app.dlNameListExcel": "下載名單",
  "app.dlCertificateListExcel": "下載證書名單",
  "app.updateCapSendDate": "更新寄送日期",
  "app.sendDate": "寄出日期",
  "app.modifyHoliday": "{year} 假日",
  "app.sendByAction": "場次寄送",
  "app.sendByPromote": "推廣寄送",
  "app.sendType": "發送類別",
  "app.internalList": "內部發送名單設定",
  "app.eventTypes": "活動類別管理",
  "app.eventMainType": "主類別",
  "app.eventSubType": "次類別",
  "app.eventMainTypeName": "主類別名稱",
  "app.eventSubTypeName": "次類別名稱",
  "app.createEventMainType": "新增主類別",
  "app.createEventSubType": "新增次類別",
  "app.eventTypeName": "類別名稱",
  "app.updateEventMainType": "更新主類別",
  "app.updateEventSubType": "更新次類別",
  "app.eventFees": "活動費用管理",
  "app.regularEvent": "一般活動",
  "app.englishCarnival": "美語嘉年華",
  "app.eventNotice": "活動類別說明及注意事項",
  "app.createVenue": "新增活動場地",
  "app.appEventBanner": "活動 Banner 範本設定",
  "app.appEventBannerCreate": "新增活動 Banner 範本",
  "app.appEventBannerUpdate": "更新活動 Banner 範本",
  "app.eventTopic": "活動主題管理",
  "app.eventTopicCreate": "新增活動主題",
  "app.eventWords": "活動單字管理",
  "app.eventProps": "活動道具管理",
  "app.amusementTax": "娛樂稅資訊管理",
  "app.eventSessions": "活動場次管理",
  "app.eventExtraFees": "活動費用補登",
  "app.regionEventBanner": "地區活動 Banner 設定",
  "app.crewType": "Crew 類別管理",
  "app.crewList": "Crew 清單管理",
  "app.crewPointValue": "Crew Points Value 維護",
  "app.crewPoint": "Crew Points 維護",
  "app.eventPreviewDoc": "活動預習單管理",
  "app.appHomeBg": "APP 首頁背景圖設定",
  "app.eventExtraFeesMaintain": "活動額外費用管理",
  "app.crossMarketUser": "跨市場使用者管理",
  "app.importantNotice": "重要通知管理",
  "app.selectBannerTime": "選擇時間段",
  "app.wfcGeneral": "WFC General PBI",
  "app.wfcCAP": "WFC CAP PBI",
  "app.capLessonName": "作業名稱",
  "app.capListWarningTitle": "是否確認更新寄送日期",
  "app.capListWarningDesc": "注意！此動作為不可逆，請再次確認",
  "app.capUnReview": "未審核總數量：{count}",
  "app.capExpired": "已過期未審核總數量：",
  "app.giftLabelWarningTitle": "是否確認列印及更新狀態",
};

export default zh_TW;
