import React from 'react';
import { IntlProvider } from 'react-intl';

import App from './App';
import en from './i18n/en';
import tw from './i18n/tw';

const Root = () => {
  const [locale, setLocale] = React.useState(navigator.language);

  let messages;
  if (locale.includes('zh')) {
    messages = tw;
  } else if (locale.includes('en')) {
    messages = en;
  }

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      defaultLocale="zh-TW"
      messages={messages}
    >
      <App setLocale={setLocale} locale={locale} />
    </IntlProvider>
  );
};

export default Root;
