import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/eventVenueActions';

interface EventVenueState {
  isLoading: boolean;
  error: string | null;
  areas: {
    typeNo: string;
    typeDesc: string;
  }[];
  venues: {
    locaNo: string;
    location: string;
  }[];
  selectVenue: {
    locaNo: string;
    location: string;
    contact: string;
    tel: string;
    fax: string;
    seats: number;
    areaFlg: string;
    lat: number;
    lng: number;
    address: string;
    memo: string;
    disabledFlag: boolean;
    childLimit: number;
    area: string;
    isEcVenue: boolean;
    mrt: string;
    bus: string;
    train: string;
    hsr: string;
    taxi: string;
    car: string;
    aircraft: string;
    boat: string;
    parking1: string;
    parkingImg1: string;
    parking2: string;
    parkingImg2: string;
    parking3: string;
    parkingImg3: string;
    parking4: string;
    parkingImg4: string;
    parking5: string;
    parkingImg5: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  areas: [],
  venues: [],
  selectVenue: null,
};

const eventVenueReducer = (
  state: EventVenueState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_VENUE_AREA_LIST_START:
      return {
        isLoading: true,
        error: null,
        areas: state.areas,
        venues: state.venues,
        selectVenue: state.selectVenue,
      };
    case ActionType.FETCH_VENUE_AREA_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        areas: action.payload,
        venues: state.venues,
        selectVenue: state.selectVenue,
      };
    case ActionType.FETCH_VENUE_AREA_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        areas: state.areas,
        venues: state.venues,
        selectVenue: state.selectVenue,
      };
    case ActionType.FETCH_VENUES_LIST_START:
      return {
        isLoading: true,
        error: null,
        areas: state.areas,
        venues: [],
        selectVenue: null,
      };
    case ActionType.FETCH_VENUES_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        areas: state.areas,
        venues: action.payload,
        selectVenue: null,
      };
    case ActionType.FETCH_VENUES_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        areas: state.areas,
        venues: [],
        selectVenue: null,
      };
    case ActionType.FETCH_VENUE_DETAIL_START:
      return {
        isLoading: true,
        error: null,
        areas: state.areas,
        venues: state.venues,
        selectVenue: null,
      };
    case ActionType.FETCH_VENUE_DETAIL_SUCCESS:
      return {
        isLoading: false,
        error: null,
        areas: state.areas,
        venues: state.venues,
        selectVenue: action.payload,
      };
    case ActionType.FETCH_VENUE_DETAIL_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        areas: state.areas,
        venues: state.venues,
        selectVenue: null,
      };
    case ActionType.CREATE_VENUE_START:
      return {
        isLoading: true,
        error: null,
        areas: state.areas,
        venues: [],
        selectVenue: null,
      };
    case ActionType.CREATE_VENUE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        areas: state.areas,
        venues: [],
        selectVenue: null,
      };
    case ActionType.CREATE_VENUE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        areas: state.areas,
        venues: [],
        selectVenue: null,
      };
    case ActionType.UPDATE_VENUE_START:
      return {
        isLoading: true,
        error: null,
        areas: state.areas,
        venues: state.venues,
        selectVenue: state.selectVenue,
      };
    case ActionType.UPDATE_VENUE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        areas: state.areas,
        venues: state.venues,
        selectVenue: action.payload,
      };
    case ActionType.UPDATE_VENUE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        areas: state.areas,
        venues: state.venues,
        selectVenue: state.selectVenue,
      };
    default:
      return state;
  }
};

export default eventVenueReducer;
