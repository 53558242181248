import axios from 'axios';

export const getApiUrl = async () => {
  try {
    const { data } = await axios.get(
      `${window.location.protocol}//${window.location.host}/apiUrl.json`
    );
    return data.apiUrl;
  } catch (error) {
    console.log('Could not load application configuration.');
    console.log(error);
  }
};
