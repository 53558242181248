import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/cap/capModifyActions';

interface CapModifyState {
  isLoading: boolean;
  error: string | null;
  childrenList: {
    seqNo: string;
    chineseName: string;
  }[];
  homeworksList: {
    seq: number;
    requestDate: string;
    lessonName: string;
    lessonDetail: string[];
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  childrenList: [],
  homeworksList: [],
};

const capModifyReducer = (
  state: CapModifyState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_CHILDREN_LIST_START:
      return {
        isLoading: true,
        error: null,
        childrenList: state.childrenList,
        homeworksList: [],
      };
    case ActionType.FETCH_CHILDREN_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childrenList: action.payload,
        homeworksList: [],
      };
    case ActionType.FETCH_CHILDREN_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childrenList: state.childrenList,
        homeworksList: [],
      };
    case ActionType.FETCH_HOMEWORKS_LIST_BY_CHILD_START:
      return {
        isLoading: true,
        error: null,
        childrenList: state.childrenList,
        homeworksList: [],
      };
    case ActionType.FETCH_HOMEWORKS_LIST_BY_CHILD_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childrenList: state.childrenList,
        homeworksList: action.payload,
      };
    case ActionType.FETCH_HOMEWORKS_LIST_BY_CHILD_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childrenList: state.childrenList,
        homeworksList: [],
      };
    case ActionType.DELETE_HOMEWORK_BY_CHILD_START:
      return {
        isLoading: true,
        error: null,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList,
      };
    case ActionType.DELETE_HOMEWORK_BY_CHILD_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList.filter(
          (homework) => homework.seq !== action.payload
        ),
      };
    case ActionType.DELETE_HOMEWORK_BY_CHILD_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList,
      };
    case ActionType.UPDATE_HOMEWORK_BY_CHILD_START:
      return {
        isLoading: true,
        error: null,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList,
      };
    case ActionType.UPDATE_HOMEWORK_BY_CHILD_SUCCESS:
      return {
        isLoading: false,
        error: null,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList,
      };
    case ActionType.UPDATE_HOMEWORK_BY_CHILD_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        childrenList: state.childrenList,
        homeworksList: state.homeworksList,
      };
    default:
      return state;
  }
};

export default capModifyReducer;
