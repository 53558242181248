import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/video/videoActions';

interface VideoState {
  isLoading: boolean;
  error: string | null;
  videos: {
    seq: number;
    videoMainType: string;
    videoMainTypeDesc: string;
    videoSubType: string;
    videoSubTypeDesc: string;
    title: string;
    videoUrl: string;
    specialViewing: boolean;
    stopFlag: boolean;
    openDate: string;
    videoContent: string;
    mp4Url: string;
    imageUrl: string;
    createDate: string;
    videoId: string;
  }[];
  selectVideo: {
    seq: number;
    videoMainType: string;
    videoMainTypeDesc: string;
    videoSubType: string;
    videoSubTypeDesc: string;
    title: string;
    videoUrl: string;
    specialViewing: boolean;
    stopFlag: boolean;
    openDate: string;
    videoContent: string;
    mp4Url: string;
    imageUrl: string;
    createDate: string;
    videoId: string;
  } | null;
}

const initialState = {
  isLoading: false,
  error: null,
  videos: [],
  selectVideo: null,
};

const videoReducer = (state: VideoState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_VIDEOS_LIST_START:
      return {
        isLoading: true,
        error: null,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_VIDEOS_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        videos: action.payload,
        selectVideo: null,
      };
    case ActionType.FETCH_VIDEOS_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.CREATE_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.CREATE_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        videos: [...state.videos, action.payload],
        selectVideo: null,
      };
    case ActionType.CREATE_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.FETCH_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        videos: state.videos,
        selectVideo: action.payload,
      };
    case ActionType.FETCH_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.UPDATE_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.UPDATE_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        videos: state.videos.map((video) => {
          if (video.seq === action.payload.seq) {
            return action.payload;
          }
          return video;
        }),
        selectVideo: null,
      };
    case ActionType.UPDATE_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.DELETE_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        videos: state.videos,
        selectVideo: null,
      };
    case ActionType.DELETE_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        videos: state.videos.filter((video) => video.seq !== action.payload),
        selectVideo: null,
      };
    case ActionType.DELETE_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        videos: state.videos,
        selectVideo: null,
      };
    default:
      return state;
  }
};

export default videoReducer;
