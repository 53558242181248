import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/supervisorSettingActions';

interface SupervisorSettingState {
  isLoading: boolean;
  error: string | null;
  supervisorList: {
    code: string;
    name: string;
  }[];
  empList: {
    code: string;
    name: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  supervisorList: [],
  empList: [],
};

const supervisorSettingReducer = (
  state: SupervisorSettingState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_TE_SUPERVISOR_LIST_START:
      return {
        isLoading: true,
        error: null,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.FETCH_TE_SUPERVISOR_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        supervisorList: action.payload,
        empList: state.empList,
      };
    case ActionType.FETCH_TE_SUPERVISOR_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.FETCH_TE_EMP_LIST_START:
      return {
        isLoading: true,
        error: null,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.FETCH_TE_EMP_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        supervisorList: state.supervisorList,
        empList: action.payload,
      };
    case ActionType.FETCH_TE_EMP_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.ADD_TE_SUPERVISOR_START:
      return {
        isLoading: true,
        error: null,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.ADD_TE_SUPERVISOR_SUCCESS:
      return {
        isLoading: false,
        error: null,
        supervisorList: [...state.supervisorList, ...action.payload],
        empList: state.empList,
      };
    case ActionType.ADD_TE_SUPERVISOR_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.DELETE_TE_SUPERVISOR_START:
      return {
        isLoading: true,
        error: null,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    case ActionType.DELETE_TE_SUPERVISOR_SUCCESS:
      return {
        isLoading: false,
        error: null,
        supervisorList: state.supervisorList.filter(
          (sup) => sup.code !== action.payload
        ),
        empList: state.empList,
      };
    case ActionType.DELETE_TE_SUPERVISOR_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        supervisorList: state.supervisorList,
        empList: state.empList,
      };
    default:
      return state;
  }
};

export default supervisorSettingReducer;
