import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/dailyLogActions';

interface DailyLogState {
  isLoading: boolean;
  error: string | null;
  lastSummary: {
    regDate: string;
    timePeriod: string;
    lastEndTime: string;
    firstCallQty: number;
    repassQty: number;
    memberQty: number;
    crewQtyActual: number;
    crewQtyScheduled: number;
    teSupervisor: string;
    childQty: number;
    bookLessonQty: number;
    songLessonQty: number;
    rightLightLessonQty: number;
    readingLessonQty: number;
    freeConversationQty: number;
    capQty: number;
  }[];
  lastDailyLogs: {
    seq: number;
    teDate: string;
    timePeriod: string;
    mainType: string;
    mainTypeName: string;
    subType: string;
    subTypeName: string;
    crew: string;
    memberNo: string;
    logContent: string;
  }[];
  specificSummary: {
    regDate: string;
    timePeriod: string;
    lastEndTime: string;
    firstCallQty: number;
    repassQty: number;
    memberQty: number;
    crewQtyActual: number;
    crewQtyScheduled: number;
    teSupervisor: string;
    childQty: number;
    bookLessonQty: number;
    songLessonQty: number;
    rightLightLessonQty: number;
    readingLessonQty: number;
    freeConversationQty: number;
    capQty: number;
  }[];
  specificDailyLogs: {
    seq: number;
    teDate: string;
    timePeriod: string;
    mainType: string;
    mainTypeName: string;
    subType: string;
    subTypeName: string;
    crew: string;
    memberNo: string;
    logContent: string;
  }[];
  rangeDailyLogs: {
    seq: number;
    teDate: string;
    timePeriod: string;
    mainType: string;
    mainTypeName: string;
    subType: string;
    subTypeName: string;
    crew: string;
    memberNo: string;
    logContent: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  lastSummary: [],
  lastDailyLogs: [],
  specificSummary: [],
  specificDailyLogs: [],
  rangeDailyLogs: [],
};

const dailyLogReducer = (
  state: DailyLogState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_LAST_TE_DAILY_LOG_START:
      return {
        isLoading: true,
        error: null,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_LAST_TE_DAILY_LOG_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lastSummary: action.payload.summary,
        lastDailyLogs: action.payload.dailyLogs,
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_LAST_TE_DAILY_LOG_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_SPECIFIC_TE_DAILY_LOG_START:
      return {
        isLoading: true,
        error: null,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_SPECIFIC_TE_DAILY_LOG_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: action.payload.summary,
        specificDailyLogs: action.payload.dailyLogs,
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_SPECIFIC_TE_DAILY_LOG_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_RANGE_TE_DAILY_LOG_START:
      return {
        isLoading: true,
        error: null,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    case ActionType.FETCH_RANGE_TE_DAILY_LOG_SUCCESS:
      return {
        isLoading: false,
        error: null,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: action.payload,
      };
    case ActionType.FETCH_RANGE_TE_DAILY_LOG_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        lastSummary: [],
        lastDailyLogs: [],
        specificSummary: [],
        specificDailyLogs: [],
        rangeDailyLogs: [],
      };
    default:
      return state;
  }
};

export default dailyLogReducer;
