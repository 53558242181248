import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/videosBySongActions';

interface VideosBySongState {
  isLoading: boolean;
  error: string | null;
  songs: {
    songNo: string;
    songName: string;
  }[];
  videos: {
    seq: number;
    performerId: number;
    performerName: string;
    songNo: string;
    videoLink: string;
    songName: string;
    videoUrl: string;
    onlineStartDt: string;
    onlineEndDt: string;
    seasonOfPromote: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  songs: [],
  videos: [],
};

const videosBySongReducer = (
  state: VideosBySongState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_SONGS_START:
      return {
        isLoading: true,
        error: null,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.FETCH_SONGS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        songs: action.payload,
        videos: state.videos,
      };
    case ActionType.FETCH_SONGS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.FETCH_FC_VIDEOS_START:
      return {
        isLoading: true,
        error: null,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.FETCH_FC_VIDEOS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        songs: state.songs,
        videos: action.payload,
      };
    case ActionType.FETCH_FC_VIDEOS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.CREATE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.CREATE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        songs: state.songs,
        videos: [...state.videos, action.payload],
      };
    case ActionType.CREATE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.UPDATE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.UPDATE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        songs: state.songs,
        videos: state.videos.map((video) => {
          if (video.seq === action.payload.seq) {
            return action.payload;
          }
          return video;
        }),
      };
    case ActionType.UPDATE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.DELETE_FC_VIDEO_START:
      return {
        isLoading: true,
        error: null,
        songs: state.songs,
        videos: state.videos,
      };
    case ActionType.DELETE_FC_VIDEO_SUCCESS:
      return {
        isLoading: false,
        error: null,
        songs: state.songs,
        videos: state.videos.filter((video) => video.seq !== action.payload),
      };
    case ActionType.DELETE_FC_VIDEO_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        songs: state.songs,
        videos: state.videos,
      };
    default:
      return state;
  }
};

export default videosBySongReducer;
