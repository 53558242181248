import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/event/eventPreviewDocActions';

interface PreviewDocState {
  isLoading: boolean;
  error: string | null;
  previewDocs: {
    actNo: String;
    blobUrl: String;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  previewDocs: [],
};

const previewDocReducer = (
  state: PreviewDocState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_PREVIEW_DOC_LIST_START:
      return {
        isLoading: true,
        error: null,
        previewDocs: state.previewDocs,
      };
    case ActionType.FETCH_PREVIEW_DOC_LIST_SUCCESS:
      return {
        isLoading: false,
        error: null,
        previewDocs: action.payload,
      };
    case ActionType.FETCH_PREVIEW_DOC_LIST_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        previewDocs: state.previewDocs,
      };
    default:
      return state;
  }
};

export default previewDocReducer;
