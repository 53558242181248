import { ActionType } from '../../actions/ActionType';
import { Action } from '../../actions/te/freeTalkSettingActions';

interface FreeTalkSettingState {
  isLoading: boolean;
  error: string | null;
  years: number[];
  topics: {
    year: number;
    details: {
      month: number;
      topics: {
        seq: number;
        year: number;
        month: number;
        sno: string;
        freeConversationSeq: number;
        topic: string;
      }[];
    }[];
  } | null;
  topicsByMonth:
    | {
        seq: number;
        year: number;
        month: number;
        sno: string;
        freeConversationSeq: number;
        topic: string;
      }[]
    | null;
}

const initialState = {
  isLoading: false,
  error: null,
  years: [],
  topics: null,
  topicsByMonth: [],
};

const freeTalkSettingReducer = (
  state: FreeTalkSettingState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_FREE_TALK_YEARS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_FREE_TALK_YEARS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: action.payload,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_FREE_TALK_YEARS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_TOPICS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_TOPICS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        topics: action.payload,
        topicsByMonth: null,
      };
    case ActionType.FETCH_TOPICS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_TOPICS_BY_MONTH_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.FETCH_TOPICS_BY_MONTH_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: action.payload,
      };
    case ActionType.FETCH_TOPICS_BY_MONTH_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.UPDATE_MONTHLY_TOPICS_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: state.topicsByMonth,
      };
    case ActionType.UPDATE_MONTHLY_TOPICS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: action.payload,
      };
    case ActionType.UPDATE_MONTHLY_TOPICS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        topics: state.topics,
        topicsByMonth: state.topicsByMonth,
      };
    case ActionType.CREATE_NEW_TOPICS_BY_YEAR_START:
      return {
        isLoading: true,
        error: null,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.CREATE_NEW_TOPICS_BY_YEAR_SUCCESS:
      return {
        isLoading: false,
        error: null,
        years: action.payload,
        topics: state.topics,
        topicsByMonth: null,
      };
    case ActionType.CREATE_NEW_TOPICS_BY_YEAR_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        years: state.years,
        topics: state.topics,
        topicsByMonth: null,
      };
    default:
      return state;
  }
};

export default freeTalkSettingReducer;
